<template>
    <div class="ms-error-page-404">
      <div class="ms-error-content">
        <div class="ms-error-status"><span>404</span> {{ $t('error.page.error.title') }}</div>
        <div class="ms-error-txt" v-html="$t('error.page.content_404')"/>
        <a
          :href="localizeUrl('/')"
          class="ms-error-btn"
          aria-label="Home link"
        >
          {{ $t("error.page.back.button") }}
        </a>
      </div>
      <div class="ms-error-img">
        <img src="@/assets/images/error-pages/error404.svg" alt="">
      </div>
    </div>
</template>

<script>
import UrlManager from '@/util/UrlManager'

export default {
  name: 'NotFoundPage',
  metaInfo () {
    return {
      title: this.$t('errorPage.404.pageTitle')
    }
  },
  methods: {
    localizeUrl(path) {
      return UrlManager.localizeUrl('', path)
    }
  }
}
</script>

<style lang="scss">
@import "../../assets/stylesheets/components/error";
</style>
<style scoped lang="scss">
  @import "../../assets/stylesheets/helpers/all-helpers";
</style>
