<template>
  <div style="position: relative">
    <h1 class="ms-form-title">{{$t('auth.oidcLogin.authPage.title')}}</h1>
    <oidc/>
  </div>
</template>

<script>
import Oidc from '@/components/pages/signin/oidc/Oidc.vue'

export default {
  name: 'OidcAuth',
  metaInfo () {
    return {
      meta: [
        {
          name: 'referer',
          content: 'no-referer'
        }
      ]
    }
  },
  components: {
    'oidc': Oidc
  }
}
</script>
