<template>
  <div class="ms-otp-input-wrap">
    <input
      ref="input"
      :type="inputType"
      :inputmode="inputMode"
      :class="inputClasses"
      min="0"
      max="9"
      :maxlength="isLastChild ? 1 : 999"
      pattern="[0-9]"
      v-model="model"
      @input="handleOnInput"
      @keydown="handleOnKeyDown"
      @paste="handleOnPaste"
      @focus="handleOnFocus"
      @blur="handleOnBlur"
    />
    <span v-if="!isLastChild && separator">
      <span v-html="separator"></span>
    </span>
  </div>
</template>

<script>
// source code https://github.com/bachdgvn/vue-otp-input

// keyCode constants
const BACKSPACE = 8;
const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;
const DELETE = 46;

export default {
  name: 'SingleOtpInput',
  props: {
    value: {
      type: String,
    },
    separator: {
      type: String,
    },
    focus: {
      type: Boolean,
    },
    inputClasses: {
      type: String,
    },
    shouldAutoFocus: {
      type: Boolean,
    },
    inputType: {
      type: String,
      default() {
        return 'tel';
      },
    },
    inputMode: {
      type: String,
      default() {
        return 'numeric';
      },
    },
    isLastChild: {
      type: Boolean,
    },
  },
  data() {
    return {
      model: this.value || '',
    };
  },
  mounted() {
    if (this.isLastChild) {
      this.$refs.input.maxLength = 1;
    }
    if (this.$refs.input && this.focus && this.shouldAutoFocus) {
      this.$refs.input.focus();
    }
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.model = newValue;
      }
    },
    // whenever question changes, this function will run
    focus(newFocusValue, oldFocusValue) {
      // Check if focusedInput changed
      // Prevent calling function if input already in focus
      if (oldFocusValue !== newFocusValue && (this.$refs.input && this.focus)) {
        this.$refs.input.focus();
        this.$refs.input.select();
      }
    },
  },
  methods: {
    handleOnInput(event) {
      const data = event.data;
      if (!data) {
        event.preventDefault();
        return;
      }

      if (data.length === 1) {
        this.$emit('on-change', data);
      } else {
        this.$emit('on-paste', data);
      }
    },
    handleOnKeyDown(event) {
      const keyEvent = (event) || window.event;
      const charCode = (keyEvent.which) ? keyEvent.which : keyEvent.keyCode;
      if (this.isCodeNumeric(charCode)
        || [BACKSPACE, LEFT_ARROW, RIGHT_ARROW, DELETE].includes(charCode)
        || keyEvent.ctrlKey || keyEvent.metaKey) {
        this.$emit('on-keydown', event);
      } else {
        event.preventDefault();
      }
    },
    isCodeNumeric(charCode) {
      // numeric keys and numpad keys
      return (charCode >= 48 && charCode <= 57) || (charCode >= 96 && charCode <= 105);
    },
    handleOnPaste(event) {
      event.preventDefault();
      const data = event.clipboardData.getData('text/plain');
      if (!data) {
        return;
      }

      return this.$emit('on-paste', data);
    },
    handleOnFocus() {
      this.$refs.input.select();
      return this.$emit('on-focus');
    },
    handleOnBlur() {
      return this.$emit('on-blur');
    },
  },
}
</script>

<style scoped lang="scss">
.ms-otp-input-wrap {
  margin-right: 8px;
  &:nth-child(4) {
    margin-right: 20px;
  }
}
</style>
