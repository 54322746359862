<template>
  <div class="ms-form-content-wrapper">
    <div class="ms-login-with-list" v-if="isCookieEnabled">
      <!--        <a href="/oauth2/authorization/google"><img src="../../../assets/images/logo-google.svg" alt="google-logo">{{$t('auth.oidcLogin.authPage.login.google')}}</a>-->
      <a :href="getAuthLink">
        <logo-microsoft />
        {{$t('auth.oidcLogin.authPage.login.microsoft')}}
      </a>
    </div>
    <div class="ms-information-block" v-else>
      {{$t('auth.oidcLogin.authPage.login.error.cookiesOff')}}
    </div>
  </div>
</template>

<script>
import LogoMicrosoft from '@/components/icons/LogoMicrosoft.vue'
import UserDataUtils from "@/util/UserDataUtils";
import {OidcProvider} from "@/constants/OidcProvider";

export default {
  name: 'Oidc',
  components: { LogoMicrosoft },
  data () {
    return {
      isCookieEnabled: navigator.cookieEnabled
    }
  },
  props: {
    authLink: String
  },
  created () {
    UserDataUtils.deleteAllUserData()
  },
  computed: {
    getAuthLink () {
      if (this.authLink) {
        return this.authLink
      }
      return OidcProvider.MS_GOOGLE.authLink
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
