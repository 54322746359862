export const OidcProvider = {
  SMN: {
    name: 'SMN',
    authLink: '/oauth2/authorization/azure',
  },
  LESPSYREUNIS: {
    name: 'LESPSYREUNIS',
    authLink: '/oauth2/authorization/lespsyreunis',
  },
  MS_AZURE: {
    name: 'MS_AZURE',
    authLink: '/oauth2/authorization/msa',
  },
  MS_GOOGLE: {
    name: 'MS_GOOGLE',
    authLink: '/oauth2/authorization/msg',
  }
}
