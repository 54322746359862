<template>
  <div class="ms-otp-content-wrap ms-otp-with-image">
    <div class="ms-otp-content">
      <div class="ms-otp-form-title">{{ $t('auth.enableTwoFactorAuthQrCode.title') }}</div>
      <div class="ms-otp-caption">{{ $t('auth.enableTwoFactorAuthQrCode.description') }}</div>
      <div class="ms-otp-qr">
        <img src="@/assets/images/download-app-qr.webp" alt="">
      </div>
      <p class="ms-qr-caption">{{ $t('auth.enableTwoFactorAuthQrCode.description2') }}</p>
      <div class="ms-app-links">
        <a
          href="https://apps.apple.com/ch/app/medicosearch/id1153385466?ls=1"
          target="_blank"
          aria-label="Apple Store"
          class="ms-app-link ms-app-store"
        >
          <img
            :src="appStoreImageLink"
            alt="app button"
            width="428"
            height="140"
            loading="lazy"
          />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.medicosearch.app"
          target="_blank"
          aria-label="Google Play"
          class="ms-app-link ms-google-play"
        >
          <img
            :src="googlePlayImageLink"
            alt="app button"
            width="472"
            height="140"
            loading="lazy"
          />
        </a>
      </div>
    </div>
    <div class="ms-otp-image-wrap">
      <img
        src="@/assets/images/pages/login-signin.webp" alt="login image">
    </div>
  </div>
</template>

<script>

export default {
  name: 'Enable2FAQR',
  computed: {
    currentLocale () {
      return this.$store.state.locale
    },
    appStoreImageLink () {
      return require(`@/assets/images/app/app-store-${this.currentLocale}.webp`)
    },
    googlePlayImageLink () {
      return require(`@/assets/images/app/google-play-${this.currentLocale}.webp`)
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/stylesheets/helpers/all-helpers';

.ms-otp-content-wrap {
  .ms-otp-form-title {
    @include mq('desktop', min) {
      margin: -12px 0 24px!important;
    }
  }
}
.ms-otp-qr {
  width: 134px;
  height: 134px;
  @include border-radius(8px);
  margin: 0 0 12px;
  img {
    width: 100%;
    height: 100%;
  }
}
.ms-qr-caption {
  font-family: $origin-fonts-medium;
  font-size: 14px;
  line-height: 19px;
  color: rgba($dark-green-color, .5);
  margin: 0 0 30px;
}

.ms-app-links {
  @include flexbox;
  @include flex-direction(row);
  margin-bottom: 77px;
  @include mq('tablet', max) {
    @include justify-content(space-between);
    width: 100%;
  }
  @include mq('tablet', min) {
    @include align-items(flex-start);
    margin-bottom: 0;
  }
  @include mq('desktop', min) {
  }
}

.ms-app-link {
  display: block;
  cursor: pointer;
  &:first-child {
    @include mq('tablet', min) {
      margin-right: 17px;
    }
    @include mq('desktop', min) {
      margin-right: 22px;
    }
    @include mq('desktop-large', min) {
      margin-right: 27px;
    }
  }
  &.ms-app-store {
    width: 148px;
    height: 48px;
    @include mq('tablet', min) {
      width: 128px;
      height: 42px;
    }
    @include mq('desktop', min) {
      width: 165px;
      height: 55px;
    }
    @include mq('desktop-large', min) {
      width: 198px;
      height: 65px;
    }
  }
  &.ms-google-play {
    width: 168px;
    height: 48px;
    @include mq('tablet', min) {
      width: 145px;
      height: 42px;
    }
    @include mq('desktop', min) {
      width: 191px;
      height: 55px;
    }
    @include mq('desktop-large', min) {
      width: 228px;
      height: 65px;
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
