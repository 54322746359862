<template>
  <footer class="ms-main-footer">
    <div class="ms-footer-container ms-container">
      <div class="ms-footer-main-col">
        <a :href="localizeUrl('/')" class="ms-footer-logo">
          <logo-without-text-image/>
        </a>
        <p>{{ $t('footer.description') }}</p>
        <div class="ms-footer-contacts">
          <div class="ms-h3-title">
            {{ $t('footer.contact.question') }}
          </div>
          <span>
            <a
              class="ms-contacts-link"
              v-bind:href="localizeUrl('/content/support/faqPatient')"
              aria-label="FAQ"
            >{{ $t('footer.actions.faq') }}</a>
          </span>
          <span>{{ $t('onboarding.page.header.contact.call_us') }} <a
            class="ms-contacts-link"
            aria-label="031 312 11 00"
            href="tel:031 312 11 00"
          > 031 312 11 00</a></span>
          <span>{{ $t('onboarding.page.header.contact.email') }} <a
            class="ms-contacts-link"
            aria-label="support@medicosearch.ch"
            href="mailto:support@medicosearch.ch"
          > support@medicosearch.ch</a></span>
        </div>
        <div class="ms-social-links">
          <a
            href="https://twitter.com/Medico_Search"
            target="_blank"
            aria-label="Medicosearch twitter"
          >
            <twitter-icon/>
          </a>
          <a
            href="https://www.linkedin.com/company/medicosearch/"
            target="_blank"
            aria-label="Medicosearch linkedin"
          >
            <linkedin-icon/>
          </a>
          <a
            href="https://www.facebook.com/Medicosearch"
            target="_blank"
            aria-label="Medicosearch facebook"
          >
            <facebook-icon/>
          </a>
        </div>
        <div class="ms-footer-app-wrap">
          <a
            href="https://apps.apple.com/ch/app/medicosearch/id1153385466?ls=1"
            target="_blank"
            aria-label="Apple Store"
          >
            <img
              :src="appStoreImageLink"
              alt="app button"
              width="428"
              height="140"
              loading="lazy"
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.medicosearch.app"
            target="_blank"
            aria-label="Google Play"
          >
            <img
              :src="googlePlayImageLink"
              alt="app button"
              width="472"
              height="140"
              loading="lazy"
            />
          </a>
        </div>
      </div>
      <div class="ms-footer-menu">
        <div class="ms-footer-row">
          <div class="ms-footer-col ms-main-col-list">
            <ul>
              <li><a v-bind:href="localizeUrl('/content/about')">{{ $t('footer.links.about') }}</a></li>
              <li><a v-bind:href="localizeUrl('/content/partners')">{{ $t('footer.links.partner') }}</a></li>
              <li><a v-bind:href="localizeUrl('/content/press')">{{ $t('footer.links.press') }}</a></li>
              <li><a v-bind:href="localizeUrl('/content/impressum')">{{ $t('footer.links.impressum') }}</a></li>
              <li><a v-bind:href="localizeUrl('/content/contacts')">{{ $t('footer.links.contact') }}</a></li>
              <li><a v-bind:href="localizeUrl('/careers')">{{ $t('footer.links.careers') }}</a></li>
              <li><a v-bind:href="localizeUrl('/blog')">{{ $t('footer.links.blog') }}</a></li>
            </ul>
          </div>
          <div class="ms-footer-col">
            <div class="ms-footer-title">
              {{ $t('footer.title.doctor') }}
            </div>
            <ul>
              <li><a v-bind:href="localizeUrl('/onboarding')">{{ $t('footer.links.information') }}</a></li>
              <li><a v-bind:href="localizeUrl('/content/support/faqMedico')">{{ $t('footer.links.faq') }}</a></li>
              <li><a v-bind:href="localizeUrl('/content/legal')">{{ $t('footer.links.privacy') }}</a></li>
              <li><a v-bind:href="localizeUrl('/content/userAgreement')">{{ $t('footer.links.doctor_agreement') }}</a></li>
              <li><a v-bind:href="localizeUrl('/onboarding')">{{ $t('footer.links.book_demo') }}</a></li>
            </ul>
          </div>
          <div class="ms-footer-col">
            <div class="ms-footer-title">
              {{ $t('footer.title.patient') }}
            </div>
            <ul>
              <li><a v-bind:href="localizeUrl('/content/userInfo')">{{ $t('footer.links.information') }}</a></li>
              <li><a v-bind:href="localizeUrl('/content/support/faqPatient')">{{ $t('footer.links.faq') }}</a></li>
              <li><a v-bind:href="localizeUrl('/content/legal')">{{ $t('footer.links.privacy') }}</a></li>
              <li><a v-bind:href="localizeUrl('/content/userAgreement')">{{ $t('footer.links.doctor_agreement') }}</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="ms-copyright">
      <div class="ms-container">
        {{ $t("global.copyright") }}
      </div>
    </div>
  </footer>
</template>

<script>

import UrlManager from '@/util/UrlManager'
import LogoWithoutTextImage from '@/components/images/footer/LogoWithoutTextImage.vue'
import TwitterIcon from '@/components/images/social/TwitterIcon.vue'
import LinkedinIcon from '@/components/images/social/LinkedinIcon.vue'
import FacebookIcon from '@/components/images/social/FacebookIcon.vue'

export default {
  name: 'Footer',
  methods: {
    localizeUrl(path) {
      return UrlManager.localizeUrl(process.env.VUE_APP_SOLUTION_HOST, path)
    },
  },
  computed: {
    currentLocale () {
      return this.$store.state.locale
    },
    appStoreImageLink () {
      return require(`@/assets/images/app/app-store-${this.currentLocale}.webp`)
    },
    googlePlayImageLink () {
      return require(`@/assets/images/app/google-play-${this.currentLocale}.webp`)
    },
  },
  components: {
    'LogoWithoutTextImage': LogoWithoutTextImage,
    'TwitterIcon': TwitterIcon,
    'LinkedinIcon': LinkedinIcon,
    'FacebookIcon': FacebookIcon,
  }
}
</script>
<style lang="scss">
  .ms-find-doctor {
    position: relative;
    @include flexbox;
    @include flex-direction(column);
    width: 100%;

    @include mq('tablet', min) {
      @include flex-direction(row);
      @include justify-content(space-between);
    }
    @include mq('desktop', min) {
      width: 490px;
    }
    @include mq('desktop-wide', min) {
    }
    @include mq('desktop-large', min) {
      width: 588px;
    }
  }

  .ms-doctor-list-wrap {
    width: 100%;
    z-index: 1;
    @include mq('tablet', max) {
      @include flexbox;
      @include flex-direction(column);
      margin-bottom: 34px;
    }
    @include mq('tablet', min) {
      max-width: 160px;
      min-width: 85px;
      width: auto;
    }
    @include mq('desktop', min) {
      margin-right: 15px;
    }

    &:last-child {
      margin: 0;
    }
  }

  .ms-doctor-list-title {
    position: relative;
    font-family: $origin-fonts-bold;
    font-size: 14px;
    line-height: 130%;
    color: $white-text;
    margin-bottom: 16px;
    background: transparent;
    border: none;
    text-align: left;
    padding: 0;
    @include mq('tablet', min) {
      font-size: 13px;
      margin-bottom: 22px;
      &:before {
        display: none;
      }
    }
    @include mq('desktop', min) {
      font-size: 14px;
    }
    @include mq('desktop-large', min) {
      font-size: 16px;
    }
  }

  .ms-doctor-list {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 7px;
      @include mq('tablet', min) {
        margin-bottom: 12px;
      }


      a {
        font-family: $origin-fonts-regular;
        font-size: 14px;
        line-height: 140%;
        color: $white-text;
        text-decoration: none;
        @include transition(.3s);
        @include mq('tablet', min) {
          font-size: 12px;
          line-height: 14px;
          &:hover {
            color: $origin-color-text;
          }
        }
        @include mq('desktop', min) {
          font-size: 14px;
        }
        @include mq('desktop-large', min) {
          font-size: 16px;
        }
      }

      &.ms-more-link {
        padding-top: 13px;
        margin: 0;
        @include mq('tablet', min) {
          padding-top: 8px;
        }

        a {
          font-family: $origin-fonts-bold;
          font-size: 14px;
          color: $origin-color-text;
          @include mq('tablet', min) {
            font-size: 12px;
          }
          @include mq('desktop', min) {
            font-size: 14px;
          }
          @include mq('desktop-large', min) {
            font-size: 16px;
          }
        }
      }
    }
  }
  </style>
  <style lang="scss" scoped>
  .ms-main-footer {
    position: relative;
    background-color: $dark-green-color;
    padding: 86px 0 0;

    @include mq('tablet', min) {
      padding: 66px 0 0;
    }
    @include mq('desktop', min) {
      padding: 85px 0 0;
    }
    @include mq('desktop-large', min) {
      padding: 135px 0 0;
    }
  }

  .ms-footer-container {
    @include flexbox;
    @include flex-direction(column);
    padding: 0 20px 45px;
    box-sizing: border-box;

    @include mq('tablet', min) {
      @include flex-direction(row);
      padding: 0 28px 50px;
    }
    @include mq('desktop', min) {
      padding: 0 0 50px;
    }
    @include mq('desktop-wide', min) {
      padding: 0 0 90px;
    }
  }

  .ms-footer-main-col {
    width: 100%;
    margin-right: auto;
    margin-bottom: 30px;
    @include mq('tablet', min) {
      max-width: 186px;
      margin-bottom: 0;
      margin-right: 55px;
    }
    @include mq('desktop', min) {
      max-width: 230px;
      margin-right: 58px;
    }
    @include mq('desktop-large', min) {
      max-width: 248px;
      margin-right: 100px;
    }

    p {
      font-family: $origin-fonts-regular;
      font-size: 14px;
      line-height: 22px;
      color: $white-text;
      margin: 0 0 44px;
      @include mq('tablet', max) {
        max-width: 237px;
      }
      @include mq('tablet', min) {
        font-size: 12px;
        line-height: 140%;
        margin: 0 0 58px;
      }
      @include mq('desktop', min) {
        font-size: 14px;
        line-height: 150%;
        margin: 0 0 48px;
      }
      @include mq('desktop-large', min) {
        font-size: 16px;
        line-height: 140%;
        margin: 0 0 55px;
      }
    }
  }

  .ms-footer-logo {
    display: block;
    margin-bottom: 15px;
    cursor: pointer;
    @include mq('tablet', min) {
      margin-bottom: 20px;
    }
    @include mq('desktop', min) {
      margin-bottom: 17px;
    }
    @include mq('desktop-large', min) {
      margin-bottom: 15px;
    }

    svg {
      @include mq('tablet', min) {
      }
      @include mq('desktop', min) {
      }
      @include mq('desktop-large', min) {
        width: 237px;
        height: 32px;
      }
    }
  }

  .ms-h3-title {
    font-family: $origin-fonts-bold;
    font-size: 19px;
    line-height: 130%;
    color: $white-text;
    margin-bottom: 16px;
    @include mq('tablet', min) {
      font-size: 16px;
    }
    @include mq('desktop', min) {
      font-size: 19px;
      margin-bottom: 18px;
    }
    @include mq('desktop-large', min) {
      font-size: 21px;
    }
  }

  .ms-footer-contacts {
    @include flexbox;
    @include flex-direction(column);
    margin-bottom: 16px;
    @include mq('desktop', min) {
      margin-bottom: 18px;
    }

    span {
      font-family: $origin-fonts-medium;
      font-size: 14px;
      line-height: 130%;
      color: $white-text;
      @include mq('tablet', min) {
        font-size: 12px;
      }
      @include mq('desktop', min) {
        font-size: 14px;
      }
      @include mq('desktop-large', min) {
        font-size: 16px;
      }

      a {
        color: $white-text;
        text-decoration: underline;
      }
    }
  }

  .ms-social-links {
    position: relative;
    left: -10px;
    margin: 0 0 45px;
    @include mq('desktop', min) {
      margin: 0 0 48px;
    }
    @include mq('desktop-large', min) {
      margin: 0 0 55px;
    }

    a {
      padding: 10px;
    }

    svg {
      width: 16px;
      height: 16px;
      fill: $white-bg;
      @include mq('desktop', min) {
        width: 19px;
        height: 19px;
      }
    }
  }

  .ms-footer-app-wrap {
    @include flexbox;
    @include flex-direction(column);

    img {
      width: 163px;
      height: 47px;
      margin-bottom: 13px;
    }
  }

  .ms-footer-menu {
    @include flexbox;
    @include flex-direction(row);
    @include justify-content(space-between);
    width: 100%;
  }

  .ms-footer-row {
    @include flexbox;
    @include flex-direction(row);
    width: 100%;
    @include mq('tablet', max) {
      @include flex-wrap(wrap);
    }
  }

  .ms-footer-col {
    width: 33.33%;
    margin-bottom: 38px;
    padding: 0 20px 0 0;
    max-width: 350px;
    @include mq('tablet', max) {
      width: 50%;
    }
    .ms-footer-title {
      font-family: $origin-fonts-bold;
      font-size: 19px;
      line-height: 128%;
      color: $white-text;
      margin-bottom: 12px;
      @include mq('tablet', min) {
        font-size: 15px;
        line-height: 121%;
      }
      @include mq('desktop', min) {
        font-size: 19px;
        line-height: 134%;
      }
      @include mq('desktop-large', min) {
        font-size: 21px;
        line-height: 121%;
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        font-family: $origin-fonts-regular;
        font-size: 16px;
        line-height: 140%;
        margin-bottom: 12px;
        @include mq('tablet', min) {
          font-size: 14px;
        }
        @include mq('desktop', min) {
        }
        @include mq('desktop-large', min) {
          font-size: 16px;
        }

        &.ms-hide-mobile {
          @include mq('tablet', max) {
            display: none;
          }
        }

        a {
          color: $white-text;
          text-decoration: none;
          @include transition(.3s);

          &:hover {
            color: $origin-color-text;
          }
        }
      }
    }

    &.ms-main-col-list {
      li {
        font-family: $origin-fonts-bold;
        font-size: 19px;
        line-height: 140%;
        color: $white-text;
        margin-bottom: 12px;
        @include mq('tablet', min) {
          font-size: 15px;
          line-height: 130%;
        }
        @include mq('desktop', min) {
          font-size: 19px;
          margin-bottom: 15px;
        }
        @include mq('desktop-large', min) {
          font-size: 21px;
          margin-bottom: 12px;
        }
      }
    }
  }

  .ms-copyright {
    @include flexbox;
    @include align-items(center);
    height: 42px;
    background: #001F28;
    font-family: $origin-fonts-regular;
    font-size: 14px;
    line-height: 130%;
    color: $white-text;
    @include mq('tablet', min) {
      font-size: 12px;
    }
    @include mq('desktop', min) {
      font-size: 14px;
    }
    @include mq('desktop-large', min) {
    }
  }
</style>
