<template>
<div>
  <h1 class="ms-form-title">{{$t('auth.oidcLogin.errorPage.title')}}</h1>
  <div class="ms-information-block ms-media-column">
    {{$t('auth.oidcLogin.errorPage.somethingWentWrong')}} <a v-bind:href="localizeUrl('/')">{{$t('auth.oidcLogin.errorPage.tryAgain')}}</a>
  </div>
</div>
</template>

<script>
import UserDataUtils from "@/util/UserDataUtils";
import UrlManager from "@/util/UrlManager";

export default {
  name: 'OidcAuthError',
  created () {
    UserDataUtils.deleteAllUserData()
  },
  methods: {
    localizeUrl (path) {
      return UrlManager.localizeUrl(process.env.VUE_APP_SOLUTION_HOST, path)
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
