import {SigninProcessStep} from "@/constants/SigninProcessStep";
import {redirectParams} from "@/main";
import UrlManager from "@/util/UrlManager";
import UserDataUtils from "@/util/UserDataUtils";
import {OidcProvider} from "@/constants/OidcProvider";

export class SigninProcessService {
  constructor (router, store, allowedNextSteps) {
    this.router = router
    this.store = store
    this.allowedNextSteps = allowedNextSteps
  }

  pushToNextSigninStep (data) {
    const nextSigninStep = data.nextSigninStep
    if (!this.allowedNextSteps.includes(nextSigninStep)) {
      this.pushToRequestSignin()
    }

    switch (nextSigninStep) {
      case SigninProcessStep.REQUEST_SIGNIN:
        this.pushToRequestSignin()
        break
      case SigninProcessStep.AUTH_OTP:
        this.pushToAuthOtp()
        break
      case SigninProcessStep.AUTH_PASSWORD:
        this.pushToAuthPassword()
        break
      case SigninProcessStep.AUTH_OIDC:
        this.pushToAuthOidc(data)
        break
      case SigninProcessStep.AUTH_2FA_CODE:
        this.pushToAuth2FACode()
        break
      case SigninProcessStep.AUTH_2FA_DEVICE:
        this.pushToAuth2FADevice()
        break
      case SigninProcessStep.REGISTRATION:
        this.pushToRegistration()
        break
      case SigninProcessStep.UPDATE_AGREEMENT:
        this.pushToUpdateAgreement()
        break
      case SigninProcessStep.UPDATE_PASSWORD:
        this.pushToUpdatePassword()
        break
      case SigninProcessStep.ENABLE_2FA:
        this.pushToEnable2FA()
        break
      case SigninProcessStep.ENABLE_2FA_SUCCESS:
        this.pushToEnable2FASuccess()
        break
      case SigninProcessStep.LOGIN:
        this.pushToLogin(data)
        break
    }
  }

  pushToRequestSignin () {
    this.router.push({ path: '/' })
  }

  pushToAuthOtp () {
    this.router.push({ path: '/signin/otp' })
  }

  pushToAuthPassword () {
    this.router.push({ path: '/signin/password' })
  }

  pushToAuth2FACode () {
    this.router.push({ path: '/signin/2fa/code' })
  }

  pushToAuth2FADevice () {
    this.router.push({ path: '/signin/2fa/device' })
  }

  pushToRegistration () {
    this.router.push({ path: '/signin/registration' })
  }

  pushToUpdateAgreement () {
    this.router.push({ path: '/signin/agreement/update' })
  }

  pushToUpdatePassword () {
    this.router.push({ path: '/signin/password/update' })
  }

  pushToEnable2FA () {
    this.router.push({ path: '/signin/2fa/enable/phone' })
  }

  pushToEnable2FASuccess () {
    this.router.push({ path: '/signin/2fa/enable/success' })
  }

  pushToLogin (data) {
    const backUrl = redirectParams.getBackUrl()
    const localizedBackUrl = UrlManager.localizeBackUrl(backUrl)
    UserDataUtils.deleteAllUserData()
    window.location.href = UrlManager.addLoginParamsToUrl(localizedBackUrl, data)
  }

  pushToAuthOidc (data) {
    const oidcProviderName = data.oidcProvider
    const oidcProvider = OidcProvider[oidcProviderName]
    if (oidcProvider && oidcProvider.authLink) {
      window.location.href = `${oidcProvider.authLink}?email=${UserDataUtils.getEmail()}&locale=${this.store.state.locale}`
    } else {
      console.error("Provider " + oidcProviderName + " is not configured")
    }
  }
}
