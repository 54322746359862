<template>
  <div id="app">
    <head-config/>
    <not-search-header v-if="isShowHeader"/>
    <main :class="[customMainClass ? customMainClass : '', 'ms-main']">
      <section :class="isEmbedUrl ? 'ms-app-wrap' : 'ms-register-wrap'">
        <div class="ms-container">
          <content-block/>
        </div>
      </section>
    </main>
    <footer-el v-if="isShowFooter"/>
  </div>
</template>

<script>
import notSearchHeader from './components/header/NoSearchHeader'
import headConfig from './components/Head'
import footer from './components/footer/Footer'
import contentBlock from './components/AllContent'
import { redirectParams } from './main'
import { LocalStorageKey } from '@/constants/LocalStorageKey'

export default {
  watch: {
    currentLocale (newValue, oldValue) {
      this.$router.changeLocale(newValue)
    }
  },
  computed: {
    currentLocale () {
      return this.$store.state.locale
    },
    isShowHeader () {
      return !this.isEmbedUrl
    },
    isShowFooter () {
      return !this.isEmbedUrl && !this.$route.meta.hideFooter
    },
    isEmbedUrl () {
      return this.$route.query && this.$route.query.displayType === 'embed'
    },
    customMainClass () {
      return this.$route.meta.customMainClass
    },
  },
  created () {
  },
  beforeDestroy () {
  },
  components: {
    'head-config': headConfig,
    'not-search-header': notSearchHeader,
    'footer-el': footer,
    'content-block': contentBlock
  },
  methods: {
  }
}
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}
html {
  position: relative;
  width: 100%;
  height: 100%;
}
body {
  width: 100%;
  height: 100%;
  overflow: auto;
  margin: 0;
}
#app {
  min-height: 100%;
  @include flexbox;
  @include flex-direction(column);
  @include align-items(stretch);
}

.ms-main {
  flex-grow: 1;
  @include flexbox;
  width: 100%;
}

.ms-header-wrap, .ms-main, .ms-footer-wrap {
  flex-shrink: 0;
}

.ms-container {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;

  @include mq('phablet', min) { // 768
    padding: 0 30px;
  }
  @include mq('tablet', min) { // 768
    padding: 0 30px;
  }
  @include mq('leptop', min) { // 1024
    padding: 0 20px;
  }
  @include mq('desktop', min) { // 1200
    max-width: 1180px;
    padding: 0;
  }
  @include mq('desktop-wide', min) { // 1440
    max-width: 1200px;
  }
}
.ms-register-wrap {
  position: relative;
  width: 100%;
  margin: 70px 0 60px;
  @include mq('tablet', min) {
    margin: 75px 0 96px;
  }
}
.ms-app-wrap {
  width: 100%;
  margin: 70px 0 0;
  @include mq('tablet', min) {
    margin: 62px 0 0;
  }
}

//Toast notification styles
.v-toast {
  margin: 20px;
  &__bottom {
  }
  &__item {
    @include flexbox;
    @include align-items(center);
    position: relative;
    min-height: unset!important;
    padding: 18px;
    padding-right: 82px;
    margin: 0!important;
    @include border-radius(9px);
    @include mq('tablet', min) {}

    .v-toast__text {
      padding: 0!important;
      font-family: $origin-fonts-medium;
      font-size: 12px!important;
      @include mq('tablet', min) {
        font-size: 18px!important;
      }
    }
    &.v-toast__item--bottom-right {

      @include mq('tablet', max) {
        align-self: center;
        width: 100%;
      }
    }
    .v-toast__icon {
      margin: 0 16px 0 0!important;
      min-width: unset!important;
    }
    &.v-toast__item--success {
      background-color: #EFFFED;
      border: 2px solid #52AC7C;
      @include border-radius(9px);
      .v-toast__icon {
        background-image: url('~@/assets/images/toast/toast-success.svg')!important;
        background-repeat: no-repeat!important;
        background-size: 100% 100%!important;
        width: 16px;
        height: 16px;
        @include mq('tablet', min) {
          width: 18px;
          height: 18px;
        }
      }
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        right: 18px;
        width: 20px;
        border-bottom: 2px solid #52AC7C;
        @include transform(translate(0%, -50%) rotate(45deg));
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 18px;
        width: 20px;
        border-bottom: 2px solid #52AC7C;
        @include transform(translate(0%, -50%) rotate(-45deg));
      }
      .v-toast__text {
        color: #52AC7C;
      }
    }
    &.v-toast__item--error {
      background-color: #FEFAFA;
      border: 2px solid #D5645F;
      @include border-radius(9px);

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        right: 18px;
        width: 20px;
        border-bottom: 2px solid #D5645F;
        @include transform(translate(0%, -50%) rotate(45deg));
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 18px;
        width: 20px;
        border-bottom: 2px solid #D5645F;
        @include transform(translate(0%, -50%) rotate(-45deg));
      }
      .v-toast__icon {
        background-image: url('~@/assets/images/toast/toast-error.svg')!important;
        background-repeat: no-repeat!important;
        background-size: 100% 100%!important;
        width: 16px;
        height: 16px;
        @include mq('tablet', min) {
          width: 18px;
          height: 18px;
        }
      }
      .v-toast__text {
        color: #D5645F;
      }
    }
  }
}
.v-toast__item--bottom-right {}
//Toast notification styles
</style>
