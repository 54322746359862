export const SigninProcessStep = {
  /**
   * First step of the signin process when the user enters his email.
   */
  REQUEST_SIGNIN: 'REQUEST_SIGNIN',
  /**
   * One of second step of the signin process when the user enters one time password from email.
   */
  AUTH_OTP: 'AUTH_OTP',
  /**
   * One of second step of the signin process when the user enters by OIDC provider.
   */
  AUTH_OIDC: 'AUTH_OIDC',
  /**
   * One of second step of the signin process when the user enters his password.
   */
  AUTH_PASSWORD: 'AUTH_PASSWORD',
  /**
   * Third step of the signin process when the user confirms authentication using one time password from sms.
   */
  AUTH_2FA_CODE: 'AUTH_2FA_CODE',
  /**
   * Third step of the signin process when the user confirms authentication using trusted devices.
   * Optional step that replace TWO_FACTOR_AUTH_CODE step available only if user has trusted devices.
   */
  AUTH_2FA_DEVICE: 'AUTH_2FA_DEVICE',
  /**
   * Stage of the signin process when the user didn't complete registration.
   */
  REGISTRATION: 'REGISTRATION',
  /**
   * Stage of the signin process when the user didn't accept up-to-date agreement.
   */
  UPDATE_AGREEMENT: 'UPDATE_AGREEMENT',
  /**
   * Fourth step of the signin process when the user has firstLoginFactor password and enabled 2FA.
   * If user forgot his password he must reset it using email OTP and 2FA.
   */
  UPDATE_PASSWORD: 'UPDATE_PASSWORD',
  /**
   * Step of the signin process when the user has active TwoFactorAuthenticationEnableRequest
   * and login using mobile app.
   */
  ENABLE_2FA: 'ENABLE_2FA',
  /**
   * Step of the signin process when the user successfully enabled 2FA.
   */
  ENABLE_2FA_SUCCESS: 'ENABLE_2FA_SUCCESS',
  /**
   * Final step of the signin process when the user is successfully authenticated.
   */
  LOGIN: 'LOGIN'
}
