<template>
  <div class="ms-partner-content">
    <div class="ms-partner">
      <img src="../../../../../assets/images/logo-mns.png" class="ms-partner-logo">
      <div class="ms-partner-name">Swiss Medical Network</div>
    </div>
    <div class="ms-partner-heading">
      <h1 class="ms-partner-title">{{$t('auth.oidcLogin.authPage.login.partner')}}SMN</h1>
      <div class="ms-partner-caption">{{$t('auth.oidcLogin.appLink.smn')}}</div>
    </div>
    <oidc :authLink="authLink"/>
  </div>
</template>

<script>
import Oidc from '@/components/pages/signin/oidc/Oidc.vue'
import {OidcProvider} from "@/constants/OidcProvider";

export default {
  name: 'SmnAuth',
  metaInfo () {
    return {
      meta: [
        {
          name: 'referer',
          content: 'no-referer'
        }
      ]
    }
  },
  data () {
    return {
      authLink: OidcProvider.SMN.authLink
    }
  },
  components: {
    'oidc': Oidc
  }
}
</script>

<style lang="scss">
</style>
