<template>
  <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.25 25.0215C5.25 25.0215 3.5 25.0215 3.5 23.2715C3.5 21.5215 5.25 16.2715 14 16.2715C22.75 16.2715 24.5 21.5215 24.5 23.2715C24.5 25.0215 22.75 25.0215 22.75 25.0215H5.25ZM14 14.5215C15.3924 14.5215 16.7277 13.9684 17.7123 12.9839C18.6969 11.9993 19.25 10.6639 19.25 9.27155C19.25 7.87916 18.6969 6.5438 17.7123 5.55923C16.7277 4.57467 15.3924 4.02155 14 4.02155C12.6076 4.02155 11.2723 4.57467 10.2877 5.55923C9.30312 6.5438 8.75 7.87916 8.75 9.27155C8.75 10.6639 9.30312 11.9993 10.2877 12.9839C11.2723 13.9684 12.6076 14.5215 14 14.5215V14.5215Z" fill="#00D0C8"/>
  </svg>
</template>

<script>
export default {
  name: 'IconPerson'
}
</script>

<style scoped>

</style>
