export default class Cookies {
  static getCookieValue(name) {
    let cookiesString = document.cookie

    let result = null
    if (cookiesString != null) {
      let cookies = cookiesString.split('; ')
      for (let cookie of cookies) {
        let nameAndValue = cookie.split('=') //[0] - cookie name and [1] - cookie value
        if (nameAndValue[0] === name) {
          result = nameAndValue[1]
          break
        }
      }
    }
    return result
  }

  static setCookieValue(name, value) {
    document.cookie = `${name}=${value}; path=/;`
  }

  static deleteCookie(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
  }
}
