import Cookies from "@/util/Cookies";

const EMAIL_COOKIE_NAME = 'user-email'
const SERVICE_NAME_COOKIE_NAME = 'service-name'
const BACK_URL_COOKIE_NAME = 'back-url'
const API_KEY_COOKIE_NAME = 'api-key'
const SIGNIN_PROCESS_TOKEN_COOKIE_NAME = 'signin-process-token'
const DISPLAY_TYPE_COOKIE_NAME = 'display-type'

export default class UserDataUtils {

  static setEmail (email) {
    Cookies.setCookieValue(EMAIL_COOKIE_NAME, email)
  }

  static getEmail () {
    return Cookies.getCookieValue(EMAIL_COOKIE_NAME)
  }

  static deleteEmail () {
    Cookies.deleteCookie(EMAIL_COOKIE_NAME)
  }

  static setServiceName (serviceName) {
    Cookies.setCookieValue(SERVICE_NAME_COOKIE_NAME, serviceName)
  }

  static getServiceName () {
    return Cookies.getCookieValue(SERVICE_NAME_COOKIE_NAME)
  }

  static deleteServiceName () {
    Cookies.deleteCookie(SERVICE_NAME_COOKIE_NAME)
  }

  static setBackUrl (backUrl) {
    Cookies.setCookieValue(BACK_URL_COOKIE_NAME, backUrl)
  }

  static getBackUrl () {
    return Cookies.getCookieValue(BACK_URL_COOKIE_NAME)
  }

  static deleteBackUrl () {
    Cookies.deleteCookie(BACK_URL_COOKIE_NAME)
  }

  static getApiKey () {
    return Cookies.getCookieValue(API_KEY_COOKIE_NAME)
  }

  static setApiKey (apiKey) {
    Cookies.setCookieValue(API_KEY_COOKIE_NAME, apiKey)
  }

  static deleteApiKey () {
    Cookies.deleteCookie(API_KEY_COOKIE_NAME)
  }

  static setDisplayType (displayType) {
    Cookies.setCookieValue(DISPLAY_TYPE_COOKIE_NAME, displayType)
  }

  static deleteDisplayType () {
    Cookies.deleteCookie(DISPLAY_TYPE_COOKIE_NAME)
  }

  static deleteAllUserData () {
    this.deleteEmail()
    this.deleteServiceName()
    this.deleteBackUrl()
    this.deleteApiKey()
    this.deleteDisplayType()
  }
}
