const AllowedService = {
  MEDICO: {
    name: 'medico',
    hosts: [
      // prod
      'https://medicosearch.ch',
      'https://m.medicosearch.ch',

      // preprod
      'https://portal.k8s.preprod.msio.cloud',
      'https://mobile.portal.k8s.preprod.msio.cloud',

      // stage
      'https://portal-00.k8s.stage.msio.cloud',
      'https://mobile-00.k8s.stage.msio.cloud',
      'https://portal-01.k8s.stage.msio.cloud',
      'https://mobile-01.k8s.stage.msio.cloud',
      'https://portal-app.k8s.stage.msio.cloud',
      'https://mobile-app.k8s.stage.msio.cloud',
      'https://ms.demo.acom.cloud',

      // local
      'https://medico.loc',
      'https://m.medico.loc',
      'http://localhost:8080'
    ]
  },
  WEB_APP: {
    name: 'web-app',
    hosts: [
      // prod
      'https://medicofolder.ch',
      'https://medicotransfer.ch',

      // stage
      'https://folder.k8s.stage.msio.cloud',
      'https://portal-00.k8s.stage.msio.cloud',
      'https://ms.demo.acom.cloud',

      // local
      'http://localhost:4040',
      'http://localhost:4545'
    ]
  },
  MOBILE_APP: {
    name: 'mobile-app',
    hosts: [
      // prod
      'https://medicosearch.ch',
      'https://m.medicosearch.ch',
      'https://medicofolder.ch',

      // preprod
      'https://portal.k8s.preprod.msio.cloud',
      'https://mobile.portal.k8s.preprod.msio.cloud',

      // stage
      'https://portal-00.k8s.stage.msio.cloud',
      'https://mobile-00.k8s.stage.msio.cloud',
      'https://portal-01.k8s.stage.msio.cloud',
      'https://mobile-01.k8s.stage.msio.cloud',
      'https://portal-app.k8s.stage.msio.cloud',
      'https://mobile-app.k8s.stage.msio.cloud',
      'https://folder.k8s.stage.msio.cloud',

      // local
      'https://medico.loc',
      'https://m.medico.loc',
      'http://localhost:8080',
      'http://localhost:4040'
    ]
  }
}

Object.freeze(AllowedService)
export default AllowedService
