<template>
  <div class="ms-otp-content-wrap ms-otp-with-image">
    <div class="ms-otp-content">
      <spinner :loading="!!loaderCount" :position="'absolute'"/>
      <h1 class="ms-otp-form-title">{{ $t('auth.otp.singInLoginPasswordPage.title') }}</h1>
      <div class="ms-otp-caption" v-html="$t('auth.otp.singInLoginPasswordPage.description', [this.email])"></div>
      <form @submit.prevent="submitAccountLogin" id="passwordStepForm">
        <div class="ms-otp-form-wrap">
          <div class="ms-otp-form-row">
            <div class="ms-otp-input-password-wrap" :class="{ 'ms-otp-error-field': passwordFieldErrorMessage }">
              <input :type="showPassword ? 'text' : 'password'"
                     name="password"
                     ref="password"
                     class="ms-otp-input mosparo__ignored-field"
                     v-model="password"
                     @keydown="onChangePasswordInput"
                     :placeholder="$t('auth.otp.singInLoginPasswordPage.password.placeholder')"
                     autocomplete="current-password"/>
              <div :class="{ 'ms-eye': showPassword, 'ms-eye-slash': !showPassword }" class="ms-show-password-btn"
                   @click="toggleShow">
                <icon-eye-slashed/>
                <icon-eye/>
              </div>
            </div>
            <div class="ms-otp-error-field">
              <span v-if="passwordFieldErrorMessage" class="ms-otp-error-text">{{ passwordFieldErrorMessage }}</span>
            </div>
          </div>
          <!-- added for avoid mosparo captcha zero fields error on back validation -->
          <input ref="hiddenInput" type="text" value="hidden" autocomplete="off" hidden>
          <div id="msp-captcha-box"></div>
          <div v-if="captchaFieldErrorMessage" class="ms-otp-error-field">
            <span class="ms-otp-error-text">{{ captchaFieldErrorMessage }}</span>
          </div>
          <div class="ms-otp-btn-wrap">
            <router-link :to="{ path: localizeUrl('/') }" class="ms-otp-btn ms-otp-btn-outline">
              {{ $t('default.back.title') }}
            </router-link>
            <input
              type="submit"
              class="ms-otp-btn"
              id="submit"
              :value="$t('default.login.title')"
              :disabled="submitLocked"
            />
          </div>
        </div>
      </form>
      <div class="ms-link-navigation">
        <a class="ms-forward-link" href="javascript:" @click="redirectToOtp">
          {{ $t('auth.otp.singInLoginPasswordPage.sendActivationCode.title') }}
        </a>
      </div>
    </div>
    <div class="ms-otp-image-wrap">
      <img
        src="@/assets/images/pages/login-signin.webp" alt="login image">
    </div>
  </div>
</template>

<script>
import IconEyeSlashed from '@/components/icons/IconEyeSlashed.vue'
import IconEye from '@/components/icons/IconEye.vue'
import UrlManager from '@/util/UrlManager'
import {signinFlowApi} from "@/api/SigninFlowApi";
import {AuthenticationRequestType} from "@/constants/AuthenticationRequestType";
import {captchaHandler} from "@/mixins/captcha-handler";
import {SigninProcessService} from "@/service/SigninProcessService";
import {SigninProcessStep} from "@/constants/SigninProcessStep";
import {mixinLoader} from "@/mixins/mixin-loader";

export default {
  name: 'AuthPassword',
  components: {
    IconEye,
    IconEyeSlashed
  },
  mixins: [captchaHandler, mixinLoader],
  data () {
    return {
      requestType: AuthenticationRequestType.PASSWORD_LOGIN,
      errors: [],
      submitLocked: false,
      email: '',
      showPassword: false,
      password: null,
    }
  },
  computed: {
    passwordFieldErrorMessage: {
      get () {
        return this.getFieldError('password')
      },
      set () {
        const locationError = this.getLocationError('password')
        if (locationError) {
          this.errors = this.errors.filter(el => el.location !== locationError.location)
        }
      }
    },
    captchaFieldErrorMessage: {
      get () {
        return this.getFieldError('captcha')
      },
      set () {
        const locationError = this.getLocationError('captcha')
        if (locationError) {
          this.errors = this.errors.filter(el => el.location !== locationError.location)
        }
      }
    }
  },
  created () {
    this.showSpinner()
    this.submitLocked = true
    signinFlowApi.loadStep({signinProcessStep: SigninProcessStep.AUTH_PASSWORD})
      .then((response) => {
        this.email = response.data.email
        return this.checkCaptchaRequired()
      })
      .catch((error) => {
        this.$router.push({ path: '/' })
      })
      .finally(() => {
        this.submitLocked = false
        this.hideSpinner()
      })

    this.signinProcessService = new SigninProcessService(
      this.$router,
      this.$store,
      [
        SigninProcessStep.AUTH_2FA_CODE,
        SigninProcessStep.AUTH_2FA_DEVICE,
        SigninProcessStep.UPDATE_AGREEMENT,
        SigninProcessStep.LOGIN,
      ]
    )
  },
  mounted () {
    if (this.$refs.password) {
      this.$refs.password.focus();
    }
  },
  methods: {
    submitAccountLogin () {
      // added for avoid safari autofill shadow content
      this.$refs.hiddenInput.name = 'hidden'
      if (!this.captchaRequired) {
        this.submitForm()
      }
    },
    submitForm () {
      if (this.submitLocked || (this.captchaRequired && !this.captchaRendered)) {
        return
      }
      this.submitLocked = true

      const params = {
        password: this.password
      }

      if (this.captchaRequired) {
        params.captchaSubmitToken = this.getSubmitToken()
        params.captchaValidationToken = this.getValidationToken()
      }

      this.showSpinner()
      signinFlowApi.authenticateByPassword(params)
        .then((response) => {
          this.signinProcessService.pushToNextSigninStep(response.data)
        })
        .catch((error) => {
          // added for avoid safari autofill shadow content
          this.$refs.hiddenInput.removeAttribute('name')
          this.errors = error.response.data.errors
          this.rerenderCaptcha().finally(() => {
            this.submitLocked = false
            this.hideSpinner()
          })
        })
    },
    redirectToOtp () {
      this.$router.push({ path: '/signin/otp' })
    },
    localizeUrl (path) {
      return UrlManager.localizeUrl('', path)
    },
    toggleShow () {
      this.showPassword = !this.showPassword
    },
    onChangePasswordInput () {
      this.passwordFieldErrorMessage = ''
    },
    getLocationError (field) {
      if (!this.errors || !field) {
        return null
      }

      return this.errors.find((el) => {
        if (el.location === field) {
          return el
        }
      })
    },
    getFieldError (field) {
      if (!this.errors) {
        return null
      }

      const it = this.errors.find((el) => {
        if (el.location === field) {
          return true
        }
      })
      if (it) {
        return this.$t(it.userMessageCode)
      }
    },
    showSpinner () {
      this.$emit('loading-increment')
    },
    hideSpinner () {
      this.$emit('loading-decrement')
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../../assets/stylesheets/helpers/all-helpers';
</style>
