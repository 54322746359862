import { render, staticRenderFns } from "./IconEye.vue?vue&type=template&id=0ed5ffd8&scoped=true&"
import script from "./IconEye.vue?vue&type=script&lang=js&"
export * from "./IconEye.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ed5ffd8",
  null
  
)

export default component.exports