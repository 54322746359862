import Vue from 'vue'
import Vuex from 'vuex'
import i18n, { ALL_LOCALES } from './i18n'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    locale: process.env.VUE_APP_I18N_DEFAULT_LOCALE,
    enable2FAPhoneNumber: '',
  },
  mutations: {
    setLocale (state, locale) {
      if (ALL_LOCALES.indexOf(locale) !== -1) {
        state.locale = locale
        i18n.locale = locale
      } else {
        throw new Error('Invalid value!')
      }
    },
    setEnable2FAPhoneNumber (state, phoneNumber) {
      state.enable2FAPhoneNumber = phoneNumber
    },
  }
})
