import moment from "moment";

export default class OtpUtils {
  static getResendLeftSeconds (otpSendInfo) {
    if (!otpSendInfo) {
      return 0
    }
    const now = moment(otpSendInfo.now)
    const retryAfter = moment(otpSendInfo.retryAfter)
    return  retryAfter.diff(now, 'seconds')
  }
}
