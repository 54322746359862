import { store } from '@/vuex'
import {ALL_LOCALES} from "@/i18n";
import AllowedService from "@/constants/AllowedService";
import {redirectParams} from "@/main";

export default class UrlManager {
  static localizeBackUrl (url) {
    let lang = store.state.locale
    if (lang === process.env.VUE_APP_I18N_DEFAULT_LOCALE) {
      return url
    } else {
      const urlData = new URL(url)
      return urlData.origin + '/' + lang + urlData.pathname
    }
  }

  static localizeUrl (host, path) {
    let lang = store.state.locale
    if (lang === process.env.VUE_APP_I18N_DEFAULT_LOCALE) {
      lang = ''
    } else {
      lang = `/${lang}`
    }
    return `${host}${lang}${path}`
  }

  static addLoginParamsToUrl (url, data) {
    const serviceName = redirectParams.getServiceName()
    if (AllowedService.WEB_APP.name !== serviceName && AllowedService.MOBILE_APP.name !== serviceName) {
      return url
    }

    const accessToken = data.accessToken
    const refreshToken = data.refreshToken
    let urlObj = new URL(url)
    if (accessToken) {
      urlObj.searchParams.append('access-token', accessToken)
    }
    if (refreshToken) {
      urlObj.searchParams.append('refresh-token', refreshToken)
    }
    return urlObj.toString()
  }

  static removeLocalizationFromUrl (url) {
    const urlData = new URL(url)
    const path = urlData.pathname
    const urlLocalized = ALL_LOCALES.some(locale => path.startsWith(`/${locale}/`))
    if (urlLocalized) {
      urlData.pathname = path.substring(3)
    }
    return urlData.toString()
  }

  static appendBaseUrl (url) {
    if (url.match('^https?://')) {
      return url
    } else {
      return `${process.env.VUE_APP_AUTH_HOST}${url}`
    }
  }
}
