/*
General usage:
---
In component add:
{
  ...
  mixins: [mixinLoader]
  ...
}
To manage loading-state:
this.$emit('loading-increment');
this.$emit('loading-decrement');

If you added the mixin in your root-component you can always trigger it from all children by:
this.$root.$emit('loading-increment');
this.$root.$emit('loading-decrement');
---

In your component-template add:
  ...
<spinner :loading="!!loaderCount" />
  ...
*/

import Spinner from '../components/helpers/Spinner'

export const mixinLoader = {
  components: {
    Spinner
  },
  data () {
    return {
      isLoading: false,
      loaderCount: 0
    }
  },
  async created () {
    this.$on('loading-increment', () => this.loadingIncrement())
    this.$on('loading-decrement', () => this.loadingDecrement())
  },
  methods: {
    loadingIncrement () {
      this.loaderCount++
      if (!this.isLoading) {
        this.isLoading = true
      }
    },
    loadingDecrement () {
      this.loaderCount--
      if (this.loaderCount === 0) {
        this.isLoading = false
      }
    }
  }
}
