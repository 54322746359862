import axios from 'axios'
import { store } from '@/vuex'
import {redirectParams} from "@/main";
import AllowedService from "@/constants/AllowedService";
import UserDataUtils from "@/util/UserDataUtils";
import {SigninProcessHeader} from "@/constants/SigninProcessHeader";

class AjaxLoader {
  constructor (axios, store) {
    this.axios = axios
    this.store = store
  }

  get (url, params = {}, headers = {}) {
    return this.handleResponse(
      this.axios.get(
        this.appendBaseUrl(url),
        { params: params, headers: this.buildHeaders(headers) }
      )
    )
  }

  post (url, params, headers = {}) {
    return this.handleResponse(
      this.axios.post(
        this.appendBaseUrl(url),
        params,
        { headers: this.buildHeaders(headers) }
      )
    )
  }

  buildHeaders (customHeaders) {
    const locale = this.store.state.locale
    const serviceName = redirectParams.getServiceName()
    const apiKey = this.getApiKey()

    const headers = {}
    if (locale) {
      headers[SigninProcessHeader.API_LOCALE] = locale
    }
    if (serviceName) {
      headers[SigninProcessHeader.SERVICE_NAME] = serviceName
    }
    if (apiKey) {
      headers[SigninProcessHeader.API_KEY] = apiKey
    }
    const mergedHeaders = { ...headers, ...customHeaders }

    return Object.fromEntries(Object.entries(mergedHeaders).filter(([key, value]) => value))
  }

  handleResponse (resp) {
    return resp
      .then((response) => {
        return this.preHandlingSuccessfulResponse(response)
      })
      .catch((error) => {
        return this.preHandlingFailedResponse(error)
      })
  }

  preHandlingSuccessfulResponse (response) {
    return response
  }

  preHandlingFailedResponse (error) {
    throw error
  }

  appendBaseUrl (url) {
    if (url.match('^https?://')) {
      return url
    } else {
      return `${process.env.VUE_APP_AUTH_HOST}${url}`
    }
  }

  getApiKey () {
    if (AllowedService.MOBILE_APP.name === redirectParams.getServiceName()
      || AllowedService.WEB_APP.name === redirectParams.getServiceName()) {
      return UserDataUtils.getApiKey()
    } else {
      return null
    }
  }
}

axios.defaults.withCredentials = true
export const ajax = new AjaxLoader(axios, store)
