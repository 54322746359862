<template>
  <svg class="ms-icon-eye-slashed" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.87289 17.129C5.02789 15.819 3.56789 14.115 2.74289 13.039C2.51219 12.742 2.38696 12.3766 2.38696 12.0005C2.38696 11.6244 2.51219 11.259 2.74289 10.962C4.23589 9.013 7.81789 5 11.9999 5C13.8759 5 15.6299 5.807 17.1299 6.874" stroke="#6A6A6A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.13 9.887C13.8523 9.60467 13.5214 9.38011 13.1565 9.22629C12.7916 9.07246 12.3998 8.99241 12.0038 8.99075C11.6078 8.98909 11.2154 9.06586 10.8491 9.21662C10.4829 9.36738 10.1502 9.58916 9.87016 9.86915C9.5901 10.1492 9.36824 10.4818 9.21739 10.848C9.06654 11.2142 8.98969 11.6066 8.99125 12.0026C8.99282 12.3986 9.07278 12.7904 9.22652 13.1554C9.38026 13.5203 9.60473 13.8512 9.887 14.129M4 20L20 4M10 18.704C10.6492 18.8972 11.3227 18.9969 12 19C16.182 19 19.764 14.987 21.257 13.038C21.4876 12.7407 21.6127 12.3751 21.6125 11.9988C21.6124 11.6226 21.4869 11.2571 21.256 10.96C20.7313 10.2755 20.1684 9.62112 19.57 9" stroke="#6A6A6A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'IconEyeSlashed'
}
</script>

<style scoped>

</style>
