import 'core-js/features/url'
import AllowedService from '@/constants/AllowedService'
import UrlManager from "@/util/UrlManager";
import UserDataUtils from "@/util/UserDataUtils";

/**
 * Class to control user redirects. It allows redirects only to hosts from given list.
 */
export class RedirectParams {

  #service = AllowedService.MEDICO
  /**
   * Url to redirect. It is important to set this value only via setter method because by security reasons
   * redirect must be done only to urls of allowed hosts.
   */
  #backUrl = process.env.VUE_APP_SOLUTION_HOST

  constructor() {
    if (!UserDataUtils.getBackUrl() || !UserDataUtils.getServiceName()) {
      this.#setDefaultRedirectData()
    } else {
      this.#setService(UserDataUtils.getServiceName())
      this.#setBackUrl(UserDataUtils.getBackUrl())
    }
  }

  setRedirectData (serviceName, backUrl) {
    if (!serviceName || !backUrl) {
      this.#setDefaultRedirectData()
      return
    }

    this.#setService(serviceName)
    this.#setBackUrl(backUrl)
  }

  getBackUrl () {
    return this.#backUrl
  }

  getServiceName () {
    return this.#service.name
  }

  isAbsoluteUrl (url) {
    return url.startsWith('//') || url.includes('://')
  }

  isRelativeUrl (url) {
    return url.startsWith('/') && (!this.isAbsoluteUrl(url))
  }

  urlIsAllowed (urlString) {
    if (this.isRelativeUrl(urlString)) {
      return true
    }
    try {
      if (urlString && urlString.includes('www.')) {
        urlString = urlString.replace('www.', '')
      }
      const url = new URL(urlString)
      return this.#service.hosts.some(item => url.hostname === new URL(item).hostname)
    } catch (e) {
      return false
    }
  }

  #setService (serviceName) {
    let service = AllowedService.MEDICO

    for (const allowedService in AllowedService) {
      if (AllowedService[allowedService].name === serviceName) {
        service = AllowedService[allowedService]
        break
      }
    }
    this.#service = service
    UserDataUtils.setServiceName(this.#service.name)
  }

  #setBackUrl (backUrl) {
    if (!this.urlIsAllowed(backUrl)) {
      this.#backUrl = process.env.VUE_APP_SOLUTION_HOST
    } else {
      this.#backUrl = UrlManager.removeLocalizationFromUrl(backUrl)
    }
    UserDataUtils.setBackUrl(this.#backUrl)
  }

  #setDefaultRedirectData () {
    this.#service = AllowedService.MEDICO
    this.#backUrl = process.env.VUE_APP_SOLUTION_HOST
    UserDataUtils.setServiceName(this.#service.name)
    UserDataUtils.setBackUrl(this.#backUrl)
  }
}
