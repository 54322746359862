import Vue from 'vue'
import VueMeta from 'vue-meta'
import App from './App.vue'
import router from './router'
import { store } from './vuex'
import i18n from './i18n'
import { RedirectParams } from '@/util/RedirectParams'
import * as Sentry from "@sentry/vue";
import {BrowserTracing} from "@sentry/vue";
import ToastPlugin from "vue-toast-notification";
import 'vue-toast-notification/dist/theme-bootstrap.css';

Vue.config.productionTip = false

export const redirectParams = new RedirectParams()

Vue.use(ToastPlugin)
Vue.use(VueMeta, { refreshOnceOnNavigation: true })

const app = {
  router,
  i18n,
  store,
  render: h => h(App)
}

const environment = process.env.VUE_APP_ENVIRONMENT
if (environment !== 'development') {
  Sentry.init({
    Vue,
    dsn: "https://d9d0c9cd08fb42c098f37b9f8060cac5@sentry.service.msio.cloud/43",
    environment: environment,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: [process.env.VUE_APP_AUTH_HOST],
      }),
      new Sentry.Replay(), // enable session replay
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 0.5 // [0.0-1.0] represents the percentage chance that any given transaction will be sent to Sentry.
  })
}

new Vue(app).$mount('#app')
