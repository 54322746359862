/*
General usage:
---
In component add:
{
  ...
  mixins: [toastNotification]
  ...
}
To show notification:
this.showSuccessNotification(successMessage)
this.showErrorNotification(errorMessage)

If you added the mixin in your root-component you can always trigger it from all children by:
this.$root.$emit('error-notification', errorMessage)
---
*/

export const toastNotification = {
  methods: {
    showSuccessNotification (message) {
      this.$toast.open({
        message: message,
        type: 'success',
        duration: 5000,
        position: 'top-right',
        dismissible: true,
        onClick: () => {},
        onDismiss: () => {},
        queue: false,
        pauseOnHover: true,
      })
    },
    showErrorNotification (message) {
      this.$toast.open({
        message: message,
        type: 'error',
        duration: 5000,
        position: 'top-right',
        dismissible: true,
        onClick: () => {},
        onDismiss: () => {},
        queue: false,
        pauseOnHover: true,
      })
    },
  },
}
