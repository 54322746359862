<!--
use example:
<spinner :loading="true" :color="#000" minHeight="100px"/>
props:
  position: relative | absolute
-->
<template>
  <div v-show="loading" :style="[styleHandler]" class="ms-spinner-wrap">
    <div class="ms-spinner">
      <img src="@/assets/images/spinner/spinner.svg"  alt=""/>
    </div>
  </div>

</template>

<script>
export default {
  name: 'Spinner',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: 'relative'
    },
    minHeight: {
      type: String,
      default: '100px'
    }
  },
  data () {
    const spinnerWrapperStyle = {
      position: this.position
    }
    if (this.position === 'relative') {
      spinnerWrapperStyle.height = this.minHeight
    }

    return {
      spinnerWrapperStyle
    }
  },
  computed: {
    styleHandler () {
      if (this.position === 'absolute') {
        this.spinnerWrapperStyle.top = '0'
        this.spinnerWrapperStyle.left = '0'
        this.spinnerWrapperStyle.right = '0'
        this.spinnerWrapperStyle.bottom = '0'
      }
      return this.spinnerWrapperStyle
    }
  }
}
</script>

<style lang="scss" scoped>
.ms-spinner {
  @include transition(all 0.3s ease-in-out 0);
  @include animation(ms-scaleout 1.0s infinite ease-in-out);

  &-wrap {
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    background: rgba(255, 255, 255, 0.8);
    z-index: 100;
  }

  img {
    display: block;
    width: auto;
    height: 36px;
    margin: 0 auto;
  }

}

@include keyframes(ms-scaleout) {
  0% {
    @include scale(0);
    opacity: 1;
  }
  100% {
    @include scale(1.0);
    opacity: 0;
  }
}

</style>
