import {signinFlowApi} from "@/api/SigninFlowApi";

export const captchaHandler = {
  data () {
    return {
      captchaRequired: false,
      captchaRendered: false,
      captchaHost: '',
      captchaIdentifier: '',
      captchaPublicKey: '',
    }
  },
  computed: {
    currentLocale () {
      return this.$store.state.locale
    },
    captchaBoxId () {
      return 'msp-captcha-box'
    },
    captchaCodeBaseId () {
      return 'msp-captcha-code-base'
    },
  },
  methods: {
    checkCaptchaRequired () {
      const params = {
        requestType: this.requestType
      }

      return signinFlowApi.captchaRequired(params)
        .then(response => {
          this.captchaRequired = response.data.captchaRequired
          this.captchaHost = response.data.captchaHost
          this.captchaIdentifier = response.data.captchaIdentifier
          this.captchaPublicKey = response.data.captchaPublicKey

          if (this.captchaRequired) {
            return this.showCaptcha()
          }
        })
    },
    showCaptcha () {
      return new Promise((resolve) => {
        let recaptchaCodeBaseScript = document.getElementById(this.captchaCodeBaseId)
        if (recaptchaCodeBaseScript) {
          this.renderCaptcha(resolve)
        } else {
          const url = `${this.captchaHost}/build/mosparo-frontend.js`
          recaptchaCodeBaseScript = document.createElement('script')
          recaptchaCodeBaseScript.setAttribute('id', this.captchaCodeBaseId)
          recaptchaCodeBaseScript.setAttribute('src', url)
          recaptchaCodeBaseScript.setAttribute('defer', '')
          recaptchaCodeBaseScript.onload = () => {
            this.renderCaptcha(resolve)
          }
          document.head.appendChild(recaptchaCodeBaseScript)
        }
      })
    },
    renderCaptcha (resolve) {
      new mosparo(
        this.captchaBoxId,
        this.captchaHost,
        this.captchaIdentifier,
        this.captchaPublicKey,
        {
          loadCssResource: true,
          cssResourceUrl: `${this.captchaHost}/resources/${this.captchaIdentifier}.css`,
          onSwitchToInvisible: () => {
            this.captchaRendered = true
            resolve()
          },
          doSubmitFormInvisible: () => {
            this.submitForm()
          },
        }
      )
    },
    rerenderCaptcha () {
      this.captchaRequired = false
      this.removeCaptchaContent()
      return this.checkCaptchaRequired()
    },
    removeCaptchaContent () {
      const newCaptchaContainer = document.createElement('div')
      newCaptchaContainer.id = this.captchaBoxId
      const captchaContainer = this.$el.querySelector('#' + this.captchaBoxId)
      captchaContainer.replaceWith(newCaptchaContainer)
      this.captchaRendered = false
    },
    getSubmitToken () {
      return this._getToken('_mosparo_submitToken')
    },
    getValidationToken () {
      return this._getToken('_mosparo_validationToken')
    },
    _getToken (tokenInputName) {
      const tokenNodes = document.getElementsByName(tokenInputName)
      if (!tokenNodes) {
        return null
      }
      const tokenNode = tokenNodes[0]
      if (!tokenNode) {
        return null
      }
      return tokenNode.value
    },
  },
}
