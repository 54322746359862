<template>
  <div class="ms-otp-input-row">
    <input @change="handleOnChange"
           autocomplete="one-time-code"
           inputmode="numeric"
           type="text"
           style="display:none;">
    <SingleOtpInput
      v-for="(item, i) in numInputs"
      :key="i"
      :focus="activeInput === i"
      :value="otp[i]"
      :separator="separator"
      :input-type="inputType"
      :input-classes="inputClasses"
      :input-mode="inputMode"
      :is-last-child="i === numInputs - 1"
      :should-auto-focus="shouldAutoFocus"
      @on-change="handleChangeValue"
      @on-keydown="handleOnKeyDown"
      @on-paste="handlePasteValue"
      @on-focus="handleOnFocus(i)"
      @on-blur="handleOnBlur"
    />
  </div>
</template>

<script>
// source code https://github.com/bachdgvn/vue-otp-input

import SingleOtpInput from './SingleOtpInput.vue';
import {OtpChannel} from "@/constants/OtpChannel";

// keyCode constants
const BACKSPACE = 8;
const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;
const DELETE = 46;

export default {
  name: 'OtpInput',
  components: {
    SingleOtpInput,
  },
  props: {
    numInputs: {
      default: 6,
    },
    separator: {
      type: String,
      default: '',
    },
    inputClasses: {
      type: String,
    },
    inputType: {
      type: String,
      validator(value) {
        return ['number', 'tel', 'password'].includes(value);
      },
    },
    inputMode: {
      type: String,
      validator(value) {
        return ['text', 'numeric', 'tel', 'none'].includes(value);
      },
    },
    shouldAutoFocus: {
      type: Boolean,
      default: true,
    },
    channel: {
      type: String,
      default: OtpChannel.EMAIL,
    }
  },
  data() {
    return {
      activeInput: 0,
      otp: [],
      oldOtp: [],
    };
  },
  mounted() {
    if (this.channel === OtpChannel.SMS && 'OTPCredential' in window) {
      navigator.credentials.get({
        otp: { transport: ['sms'] },
      }).then((receivedOtp) => {
        const receivedOtpCode = (receivedOtp.code).toString();
        this.$set(this, 'otp', receivedOtpCode.split(''));
        this.focusInput(receivedOtpCode.length);
        return this.checkFilledAllInputs();
      }).catch((error) => {
        console.error(error)
      })
    }
  },
  methods: {
    handleOnFocus(index) {
      this.activeInput = index;
    },
    handleOnBlur() {
      this.activeInput = -1;
    },
    // Helper to return OTP from input
    checkFilledAllInputs() {
      if (this.otp.join('').length === this.numInputs) {
        return this.$emit('on-complete', this.otp.join(''));
      }
      return 'Wait until the user enters the required number of characters';
    },
    // Focus on input by index
    focusInput(input) {
      this.activeInput = Math.max(Math.min(this.numInputs - 1, input), 0);
    },
    // Focus on next input
    focusNextInput() {
      this.focusInput(this.activeInput + 1);
    },
    // Focus on previous input
    focusPrevInput() {
      this.focusInput(this.activeInput - 1);
    },
    // Change OTP value at focused input
    changeCodeAtFocus(value) {
      this.oldOtp = Object.assign([], this.otp);
      this.$set(this.otp, this.activeInput, value);
      if (this.oldOtp.join('') !== this.otp.join('')) {
        this.$emit('on-change', this.otp.join(''));
        this.checkFilledAllInputs();
      }
    },
    handlePasteValue(value) {
      const clearedValue = value.replace(/\D/g, '')
        .slice(0, this.numInputs - this.activeInput)
        .split('');

      // Paste data from focused input onwards
      const currentCharsInOtp = this.otp.slice(0, this.activeInput);
      const combinedWithPastedData = currentCharsInOtp.concat(clearedValue);
      this.$set(this, 'otp', combinedWithPastedData.slice(0, this.numInputs));
      this.focusInput(combinedWithPastedData.slice(0, this.numInputs).length);
      return this.checkFilledAllInputs();
    },
    handleOnChange(e) {
      const value = e.target.value;
      if (!value) {
        e.preventDefault();
      } else if (value.length > 1) {
        this.handlePasteValue(value);
      } else {
        this.handleChangeValue(value);
      }
    },
    handleChangeValue(value) {
      this.changeCodeAtFocus(value);
      this.focusNextInput();
    },
    clearInput() {
      if (this.otp.length > 0) {
        this.$emit('on-change', '');
      }
      this.otp = [];
      this.activeInput = 0;
    },
    // Handle cases of backspace, delete, left arrow, right arrow
    handleOnKeyDown(event) {
      switch (event.keyCode) {
        case BACKSPACE:
          event.preventDefault();
          this.changeCodeAtFocus('');
          this.focusPrevInput();
          break;
        case DELETE:
          event.preventDefault();
          this.changeCodeAtFocus('');
          break;
        case LEFT_ARROW:
          event.preventDefault();
          this.focusPrevInput();
          break;
        case RIGHT_ARROW:
          event.preventDefault();
          this.focusNextInput();
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.ms-otp-input-row {
  @include flexbox;
}
</style>
